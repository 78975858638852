<template>
  <div class="sidebar-item">
    <template v-if="!item.children">
      <MenuItem :index="item.path">
        <!-- <i :class="`mg-r-10 iconfont ` + item.icon"></i> -->
        <svg class="mg-r-10 fz-20 icon" aria-hidden="true">
          <use :xlink:href="`#` + item.icon"></use>
        </svg>
        <span slot="title">{{ item.name }}</span>
      </MenuItem>
    </template>
    <Submenu v-else :index="item.path">
      <template slot="title">
        <div class="flex items-center">
          <svg v-if="item.icon" class="mg-r-10 fz-20 icon" aria-hidden="true">
            <use :xlink:href="`#` + item.icon"></use>
          </svg>
          <p>{{ item.name }}</p>
        </div>
      </template>
      <MenuItem v-for="item in item.children" :key="item.path" :index="item.path" :disabled="isDisabled(item.name)">
        <span slot="title">{{ item.name }}</span>
      </MenuItem>
    </Submenu>
  </div>
</template>

<script>
import { MenuItem, Submenu } from 'element-ui';

export default {
  name: 'sidebarItem',
  components: {
    MenuItem,
    Submenu,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  methods: {
    // 菜单是否被禁用
    isDisabled(menuName) {
      if (this.userInfo.thirdpartySchoolProjectCode !== '') {
        const blackList = ['升年级'];
        return blackList.includes(menuName);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-item {
  ::v-deep .el-submenu {
    .el-submenu__title {
      border-left: 4px solid #ffffff;

      &.is-active,
      &:hover {
        color: $themeColor !important;
        background-color: #f1fffc !important;
        border-left: 4px solid $themeColor;
      }
    }
  }
}
</style>
