<template>
  <div class="backStage">
    <Header></Header>
    <div class="backStage-wrapper">
      <SideBar></SideBar>
      <div class="backStage-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 管理页
 */

import Header from './components/header';
import SideBar from './components/sidebar';
export default {
  name: 'backStage',
  components: {
    Header,
    SideBar,
  },
  methods: {},
  mounted() {
    // 检查导入状态
    // this.$batchEntry.checkTask();
    //三级等保-是否已经进行过手机绑定
    this.$tlg.check();
  },
  destroyed() {
    //销毁手机绑定弹窗
    this.$tlg.destroy();
  },
};
</script>

<style lang="scss" scoped>
.backStage {
  &-wrapper {
    display: flex;
    background: #f8f8f8;
    height: calc(100vh - 64px);
  }
  &-container {
    flex: 1;
    margin: 16px 16px 0;
    padding: 4px 24px 24px;
    background: #ffffff;
    overflow-x: auto;
    border-radius: 8px 8px 0 0;
  }
  &::v-deep {
    .el-table th {
      background: #f6f8fb;
    }
  }
}
</style>
