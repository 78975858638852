<template>
  <div class="sidebar">
    <Menu
      :default-active="activeRoute"
      unique-opened
      router
      background-color="#fff"
      text-color="#2C2F2E"
      active-text-color="#38C3A1"
    >
      <sidebar-item v-for="item in sidebarData" :key="item.path" :item="item"></sidebar-item>
    </Menu>
  </div>
</template>

<script>
import { Menu } from 'element-ui';
import SidebarItem from './sidebarItem.vue';

export default {
  components: {
    Menu,
    SidebarItem,
  },
  data() {
    return {
      sidebarData: [],
    };
  },
  computed: {
    activeRoute() {
      return this.$route.path;
    },
  },
  methods: {
    getSidebarData() {
      let backStageRoutes = [...this.$store.state.route.routes.find((x) => x.path === '/back-stage').children];

      this.sidebarData = getChildren(backStageRoutes, '/back-stage');

      function getChildren(routes, path) {
        let children = [];

        routes.forEach((route) => {
          if (route.meta.hidden) return;

          let routePath = `${path}/${route.path}`;
          let child = {
            path: routePath,
            name: route.meta.title || '',
            icon: route.meta.icon || '',
          };

          if (route.children) {
            child.children = getChildren(route.children, routePath);
          }

          children.push(child);
        });

        return children;
      }
    },
  },
  created() {
    this.getSidebarData();
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
  width: 240px;
  background-color: #ffffff;
  flex-shrink: 0;
  padding-top: 16px;
  overflow: auto;

  ::v-deep .el-menu {
    border-right: none;
  }

  ::v-deep .el-menu-item {
    border-left: 4px solid #ffffff;
    height: 56px;

    &.is-active,
    &:hover {
      color: $themeColor !important;
      background-color: #f1fffc !important;
      border-left: 4px solid $themeColor;

      svg {
        fill: $themeColor;
      }
    }
  }
}
</style>
